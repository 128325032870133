import React from "react";
import client from "../assets/images/client.png";
import { FaBullseye, FaHandshake, FaSeedling } from "react-icons/fa";
import img1 from "../assets/gallery/1.jpeg";
import img2 from "../assets/gallery/2.jpeg";
import img3 from "../assets/gallery/3.jpeg";
import img4 from "../assets/gallery/4.jpeg";
import img5 from "../assets/gallery/5.jpeg";
import img6 from "../assets/gallery/6.png";
import img7 from "../assets/gallery/7.jpeg";

const About = () => {
  return (
    <div>
      <div className="about" id="">
        <p className="sub-title">OUR GALLERY</p>
        <h4 className="title">Ngola In Pictures</h4>
        <div className="gallery-wrapper">
          <div className="gallery">
            <img src={img1} />
            <img src={img2} />
            {/* <img src={img3} /> */}
            <img src={img4} />
            <img src={img5} />
            <img src={img6} />
            <img src={img7} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
