import React from "react";
import { FiMapPin, FiMail } from "react-icons/fi";
import { BsTelephone, BsWhatsapp } from "react-icons/bs";
import img3 from "../assets/gallery/4.jpeg";

const Contact = () => {
  return (
    <div className="contact" id="contact">
      <p className="contact-sub-title">CONTACT US</p>
      <h4 className="contact-title">TALK TO US</h4>
      <div className="contact-wrapper">
        <div>
          <img className="contact-info" src={img3} />
        </div>
        <div className="contact-form">
          <div className="names">
            <label>
              First Name
              <input type="text" className="name" placeholder="John" />
            </label>
            <label>
              Last Name
              <input type="text" className="name" placeholder="Doe" />
            </label>
          </div>
          <div className="names">
            <label>
              Email Address
              <input
                type="email"
                className="email"
                placeholder="johndoe@example.com"
              />
            </label>
            <label>
              Phone Number
              <input
                type="number"
                className="number"
                placeholder="+254 712 345 678"
              />
            </label>
          </div>
          <div className="names">
            <label>
              Your Message
              <input
                type="text"
                className="message"
                placeholder="hi, i would like ....."
              />
            </label>
          </div>
          <button className="submit">SEND</button>
        </div>
      </div>
    </div>
  );
};

export default Contact;
