import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../assets/images/logo.png";
import { AiOutlineInstagram } from "react-icons/ai";
import { MdOutlineFacebook } from "react-icons/md";
import { AiFillTwitterCircle } from "react-icons/ai";
import { BsTelephone, BsWhatsapp } from "react-icons/bs";
import { FiMail, FiMapPin } from "react-icons/fi";
import { IoTimeOutline } from "react-icons/io5";

const Footer = () => {
  return (
    <div>
      <div className="footer">
        <div className="footer-wrapper">
          <div className="left">
            <img className="footer-logo" src={logo} alt="" />
            <div className="social-media">
              <AiOutlineInstagram className="social-icon" />
              <MdOutlineFacebook className="social-icon" />
              <AiFillTwitterCircle className="social-icon" />
            </div>
            <p className="copyright">2024 Ngola Minerals Limited.</p>
          </div>
          <div className="footer-info">
            <h4 className="sub-title">OUR INFORMATION</h4>
            <h6 className="info">Ngola Minerals Limited</h6>
            <p className="info mail">
              <IoTimeOutline className="footer-icon" />
              Mon - Fri 8am to 6pm
            </p>
            <p className="info mail">
              <FiMail className="footer-icon" />
              info@ngolaminerals.com
            </p>
            <a href="https://wa.me/234974183316" className="info" id="one">
              <h4>OPERATIONS MANAGER:</h4>
              <p className="call">
                <BsTelephone className="footer-icon" />
                +237 683 675 751
              </p>
            </a>
            <a href="tel:243973114417" className="info" id="one">
              <h4>OFFICE LINE:</h4>
              <p className="call">
                <BsTelephone className="footer-icon" />
                +237 000 000 000
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
